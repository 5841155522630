import { Rectangle, Texture, Sprite } from 'pixi.js';
import SnakeCell from './SnakeCell';

const UP = 'up';
const DOWN = 'down';
const RIGHT = 'right';
const LEFT = 'left';
const DEFAULT_DIRECTION = RIGHT;


function degrees_to_radians(degrees)
{
  var pi = Math.PI;
  return degrees * (pi/180);
}

const demo = [[6,10],[5,10],[4,10],[3,10]];

export default class Snake {
  constructor(graphics, collision, textures) {
    this.textures = textures;

    this._graphics = graphics;
    this._collision = collision;


    const rectangle = new Rectangle(0, 0, 64, 64);
    this.rectangle = rectangle;

    this.bodyTexture = new Texture(
      textures.body.baseTexture,
      rectangle,
    );

    //Create the sprite from the texture
    this.head = new Sprite(new Texture(
      textures.head.baseTexture,
      rectangle,
    ));

  

    this.tail = new Sprite(new Texture(
      textures.tail.baseTexture,
      rectangle,
    ));

    /* this.cells = [
      new SnakeCell(4, 2),
      new SnakeCell(3, 2),
      new SnakeCell(3, 3),
      new SnakeCell(3, 4),
      new SnakeCell(3, 5),
    ]; */

    this.reset();
    
  }

  reset() {
    this.cells = demo.map(([x, y]) => {
      return new SnakeCell(x, y)
    });

    this.previousDirection = RIGHT;
    this.nextDirection = DEFAULT_DIRECTION;
    this.direction = null;

    this.body = this.cells.map((p, idx) => {
      if (idx > 0 ) {
        const body = new Sprite(this.bodyTexture);
        body.interactive = true;
        body.on('mousedown', this.onBodyClick.bind(this, idx));
        return body;
      } else {
        if (idx === 0) {
          return this.head;
        }
      }
    });
  }

  toStage() {
    return [
      this._graphics,
      ...this.body.filter((d) => d !== null)
    ]
  }

  get graphics() {
    return this._graphics;
  }

  right() {
    if (this.direction === LEFT) return;
    this.setDirection(RIGHT);
  }

  left() {
    if (this.direction === RIGHT) return;
    this.setDirection(LEFT);
  }

  up() {
    if (this.direction === DOWN) return;
    this.setDirection(UP);
  }

  down() {
    if (this.direction === UP) return;
    this.setDirection(DOWN);
  }

  setDirection(direction) {
    this.previousDirection = this.direction;
    this.nextDirection = direction;
  }

  grow(stage) {
    const lastCell = this.cells[this.cells.length - 1];
    const cell = new SnakeCell(lastCell.x, lastCell.y);

    const oldTail = this.body[this.body.length - 1];
    oldTail.texture = new Texture(
      this.textures.body.baseTexture,
      this.rectangle,
    );

    this.cells.push(cell);

    const bodySprite = new Sprite(this.tail.texture);
    bodySprite.interactive = true;
    bodySprite.on('mousedown', this.onBodyClick.bind(this, this.body.length - 1));
    bodySprite.width = cell.width;
    bodySprite.height = cell.height;
    bodySprite.position.set(cell.x * (20), cell.y * 20);
    this.body.push(bodySprite);
    stage.addChild(bodySprite);
  }

  onBodyClick(index) {
    const previous = this.cells[index - 1];
    const next = this.cells[index + 1];
    const current = this.cells[index];
    const { type } = this.directionDetection(index, this.cells, this.cells.length);
    console.table({
      index,
      previous: [previous.x, previous.y],
      current: [current.x, current.y],
      next: [next.x, next.y],
      type
    })
  }

  getHead() {
    return this.cells[0];
  }

  getBody() {
    const cells = [...this.cells];
    cells.shift();
    return cells;
  }

  getBodyArray() {
    return this.cells.map((cell) => {
      return [cell.x, cell.y];
    });
  }

  hasCollidedWithSelf() {
    return this._collision.hasCollided(this.getHead(), this.getBody());
  }

  hasGoneOutOfBounds() {
    const collied = this._collision.hasGoneOutOfBounds(this.getHead());
    return collied;
  }

  deepCloneCells() {
    return JSON.parse(JSON.stringify(this.cells));
  }

  moveHead(){
    const directions = {
      [LEFT]: () => this.getHead().decrementX(),
      [RIGHT]: () => this.getHead().incrementX(),
      [UP]: () => this.getHead().decrementY(),
      [DOWN]: () => this.getHead().incrementY(),
    };

    directions[this.direction]();
  }

  move() {
    this.direction = this.nextDirection;
    const previousCells = this.deepCloneCells();

    this.moveHead();

    this.cells = this.cells.map((cell, i) => {
      const isHeadOfSnake = i === 0;
      if (isHeadOfSnake) return cell;

      const cellToUpdate = cell;
      const previousCell = previousCells[i - 1];
      cellToUpdate.x = previousCell.x;
      cellToUpdate.y = previousCell.y;

      return cellToUpdate;
    });
  }

  flash() {
    this.graphics.clear();
    setTimeout(() => this.draw(), 200);
    setTimeout(() => this.graphics.clear(), 400);
    setTimeout(() => this.draw(), 600);
    setTimeout(() => this.graphics.clear(), 800);
    setTimeout(() => this.draw(), 1000);
  }

  directionDetection(index, cells, cellsLength) {
    const previous = cells[index - 1];
    const next = cells[index + 1];
    const current = cells[index];

    const state = {
      rotation: 0,
      anchor: [0, 0],
      direction: '',
      type: 'normal',
      texture: (new Texture(
        this.textures.body.baseTexture,
        this.rectangle,
      ))
    }

    if (previous.x === current.x) { // same col (up/down)
      if (previous.y < current.y) {
        // up
        state.rotation = 90;
        state.anchor = [0, 1]
        state.direction = UP;
      } else {
        // down
        state.rotation = -90;
        state.anchor = [1, 0]
        state.direction = DOWN;
      }
    }

    if (previous.y === current.y) { // same row (left/right)
      if (previous.x < current.x) {
        // left
        state.rotation = 0;
        state.anchor = [0, 0]
        state.direction = LEFT;
      } else {
        // right
        state.rotation = 180;
        state.anchor = [1, 1]
        state.direction = RIGHT;
      }
    }

    if (index > 0 && index !== cellsLength - 1 ) { // if body
      if ((previous.x === current.x) && (next.x !== current.x)) {
        state.type = 'angle';
        state.texture = (new Texture(
          this.textures.angle.baseTexture,
          this.rectangle,
        ))

        if (next.x > current.x) {
          
          
          if (previous.y > current.y) {
            state.type = '> angle ⎾';
            state.rotation = 180;
            state.anchor = [1, 1]
          } else {
            state.type = '< angle ⎾';
            
          }

        } else {
          if (previous.y > current.y) {
            state.type = '> angle ⏋';
            state.rotation = -90;
            state.anchor = [1, 0]
          } else {
            state.type = '< angle ⏋';
            state.rotation = 0;
            state.anchor = [0, 0]
          }
        }
      }


      if ((previous.x > current.x) && (next.x === current.x)) {
        state.type = 'angle';
        state.texture = (new Texture(
          this.textures.angle.baseTexture,
          this.rectangle,
        ))
        if (next.y < current.y) { // ⎾
          state.type = 'x angle ⎾';
          state.rotation = 90;
          state.anchor = [0, 1]
        } else { // ⎿
          state.type = 'angle ⎿';
        }
      }

      if ((previous.x < current.x) && (next.x === current.x)) {
        
        state.texture = (new Texture(
          this.textures.angle.baseTexture,
          this.rectangle,
        ))
        if (next.y > current.y) { // ⏋
          state.rotation = -90;
          state.anchor = [1, 0]
          state.type = 'angle ⏋';
        } else { // ⏌
          state.type = 'angle ⏌';
        }
      }
    }

    return state;
  }

  draw() {
    this.graphics.clear();

    const cellsLength = this.cells.length;
    this.cells.forEach((cell, idx) => {
      this.graphics.beginFill(idx === 0 ? 0x66CCFF : 0xADFF2F);
      

      if (idx > 0) {
        
      } else if (idx === 0) {
        

        
        this.head.scale.set(1, 1);

        let rotation = -90;
        if (this.direction === UP) {
          rotation = 90;
          this.head.anchor.set(0, 1)

          if (this.previousDirection === RIGHT) {
            this.head.scale.set(1, -1);
            this.head.anchor.set(0, 0)
          }
          
        } else if (this.direction === DOWN) {
          this.head.anchor.set(1, 0)

          if (this.previousDirection === RIGHT) {
            this.head.scale.set(1, -1);
            this.head.anchor.set(1, 1)
          }

          
          rotation = -90;
        } else if (this.direction === RIGHT) {
          rotation = -180;
          this.head.scale.set(1, -1);
          this.head.anchor.set(1, 0)
        } else if (this.direction === LEFT) {
          this.head.anchor.set(0, 0)
          rotation = 0;
        } 


        this.head.width = cell.width;
        this.head.height = cell.height;
        this.head.rotation = degrees_to_radians(rotation)
        this.head.position.set(cell.x * (20), cell.y * 20);
      }


      
      
      
      if (idx === this.cells.length - 1) { // tail
        const directionState = this.directionDetection(idx, this.cells, cellsLength);
          this.body[idx].texture = new Texture(
            this.textures.tail.baseTexture,
            this.rectangle,
          );
         this.body[idx].width = cell.width;
         this.body[idx].height = cell.height;
         this.body[idx].position.set(cell.x * (20), cell.y * 20);
         this.body[idx].rotation = degrees_to_radians(directionState.rotation);
         this.body[idx].anchor.set(directionState.anchor[0], directionState.anchor[1]);
      } else if (idx !== 0) { // body
        const directionState = this.directionDetection(idx, this.cells, cellsLength);

        if (idx === 1) {
          this.graphics.beginFill(0xFFBAF1);
        }

        const body = this.body[idx];
        body.texture = directionState.texture;
        body.width = cell.width;
        body.height = cell.height;
        body.position.set(cell.x * (20), cell.y * 20);
        body.rotation = degrees_to_radians(directionState.rotation);
        body.anchor.set(directionState.anchor[0], directionState.anchor[1]);
      }

      
    });
  }
}
