import Database from './Database';


export default class HighScore {
  constructor(app) {
    this.app = app;

    this.db = new Database();
    this.db.init();

    this.el_gameOver = document.querySelector('.game-over');
    this.el_highScore = document.querySelector('.high-score');
    this.el_newScore = document.querySelector('.new-score');
    this.el_gameOver.querySelector('.play').addEventListener('click', this.handlePlay.bind(this));
    this.el_highScore.querySelector('.play').addEventListener('click', this.handlePlay.bind(this));

    this.el_newScore.querySelector('form').addEventListener('submit', this.handleSubmit.bind(this));
    this.el_newScore.querySelector('form input').addEventListener('keydown', this.handleKeydown.bind(this));

    this.score = 0;
    this.bottle = 0;

    this.highscores_table = [];
  }

  getLatest(cb) {
    this.db.retrieveHighScore((lowest, all) => {
      this.highscores_table = all;
      cb(lowest, all);
    });
  }

  setHighScore(username, cb) {

    this.db.setHighScore(username, this.score, this.bottle).then(() => {
      this.hideNewScore();
      cb();
    }).catch((err) => {
      console.log('err', err)
      this.hideNewScore();
      this.el_gameOver.classList.remove('hide');
      
    });
  }

  displayForm(score, bottle) {
    this.el_gameOver.classList.add('hide');
    this.score = score;
    this.bottle = bottle;
    this.el_newScore.classList.remove('hide');
    this.el_newScore.querySelector('.score-number').innerText = score;
  }

  displayScoresTable() {
    this.el_gameOver.classList.add('hide');
    this.hideNewScore();
    this.el_highScore.classList.remove('hide');
    const ul_element = this.el_highScore.querySelector('ul')

    ul_element.innerHTML = '';

    let html = '';
    this.highscores_table.forEach((item) => {
      const { username, score } = item.data();
      html += `<li>
                  <span>${username}</span>
                  <span>${score}</span>
              </li>`;
    });

    ul_element.innerHTML = html;
  }

  hideHighScore() {
    this.el_highScore.classList.add('hide');
  }
  

  hideNewScore() {
    
    this.el_newScore.classList.add('hide');
  }
  
  handleKeydown(e) {

  }

  handlePlay() {
    this.el_gameOver.classList.add('hide');
    this.hideHighScore();
    this.app.newGame();
    firebase.analytics().logEvent('try_again');
  }

  handleSubmit(e) {
    this.el_gameOver.classList.add('hide');
    e.stopPropagation();
    e.preventDefault();

    const username = this.el_newScore.querySelector('input').value;

    this.setHighScore(username, () => {
      this.getLatest(() => {
        this.displayScoresTable();
      });
    });
  }
}