import { Rectangle, Texture, Sprite } from 'pixi.js';
import Cell from '../Snake/Cell';

export default class Food extends Cell {
  constructor(x, y, texture) {
    super(x, y, texture);


    const rectangle = new Rectangle(0, 0, 64, 64);
    const foodTexture = new Texture(
      this.texture.baseTexture,
      rectangle,
    );

    //Create the sprite from the texture
    let rocket = new Sprite(foodTexture);
    rocket.width = 20;
    rocket.height = 20;
    this.graphics = rocket;
  }

  randomlyReposition(snake, x, y) {
    const snake_cells = snake.getBodyArray();
    const position = this.getCorrectNewPosition(snake_cells, x, y);

    this.x = position[0];
    this.y = position[1];
  }


  getCorrectNewPosition(snake_cells, x, y) {
    const ramdomPosition = (x, y) => {
      return [
        Math.round(Math.random() * x),
        Math.round(Math.random() * y)
      ];
    }
    const [new_x, new_y] = ramdomPosition(x,y);
    

    const index = snake_cells.findIndex((cell) => new_x === cell[0] && new_y === cell[1]);
    if (index !== -1) {
      return this.getCorrectNewPosition(snake_cells, x, y);
    }

    return [new_x, new_y];
  }

  get position() {

  }

  draw() {
    const draw_x = (this.x * (20));
    const draw_y = (this.y * (20));
    this.graphics.position.set(draw_x, draw_y);
  }
}
