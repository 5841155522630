

export default class Database {
  constructor() {
    this.db = firebase.firestore();
    this.limit = 15;
    this.collectionName = 'scores-new';
    this.lowestInScore = 0;
  }

  init() {
    // this.setHighScore('ok', 2450);
    // this.retriveHighScore();
  }

  retrieveHighScore(cb) {
    this.db.collection(this.collectionName).orderBy("score", "desc").limit(this.limit).get().then((querySnapshot) => {
      if (querySnapshot.empty) {
        this.lowestInScore = 0;
      } else {
        this.lowestInScore = +querySnapshot.docs.reverse()[0].data().score;      
      }

      cb(this.lowestInScore, querySnapshot.docs);
    });

    
  }

  generateRamdomID() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  setHighScore(username, score, bottle) {
    
    return new Promise((accept, reject) => {
      /* if (score < this.lowestInScore) {
        return reject();
      } */
  

  
      this.db.collection(this.collectionName).doc(this.generateRamdomID()).set({
        username,
        score,
        bottle,
        timestamp: Date.now()
      }).then(accept()).catch(reject());
  
    });
  }
}