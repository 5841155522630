import lottie from 'lottie-web';
export default class Intro {
  constructor(status, startCallback = () => {}) {
    firebase.analytics().logEvent('intro');


    
    this.toLoad = 3;
    
    this.logoAnim = lottie.loadAnimation({
      container: document.querySelector('.Title__SVG'), // the dom element that will contain the animation
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: './anim/serpandemie.json' // the path to the animation json
    });

    this.logoAnim.addEventListener('data_ready', () => {

      this.onLoad();
    });

    this.logoAnim.addEventListener('complete', function() {
      document.querySelector('.Title__Text').classList.add('appear');
    });

    const els = document.querySelectorAll('.Snake_Animation__SVG');
    
    els.forEach((el) => {
      const anim = lottie.loadAnimation({
        container: el, // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: './anim/snake_head.json' // the path to the animation json
      });
  
      anim.addEventListener('data_ready', () => {
        this.onLoad();
        const svg = el.querySelector('svg')
        svg.setAttribute('viewBox', '0 0 180 148');
        // svg.setAttribute('style', 'width: 173px; height: 159px;')
        svg.querySelector('g').setAttribute('style', 'transform: translate(-265px, -186px)');
      })
    })

    let isStarted = false;

    document.querySelector('.Page__Game__Play_Btn').addEventListener('click', function(e) {
      e.stopPropagation();
      e.preventDefault();

      if (isStarted) {
        return;
      }

      document.querySelector('.Game').classList.add('appear');
      setTimeout(() => {
        firebase.analytics().logEvent('play');
        startCallback();
        status.setRunning();

        isStarted = true;
      }, 300);
    });

    let isAnimationEnded = false;
    document.querySelector('.Snake_Animation__Container').addEventListener('animationiteration', function() {

      if (!isAnimationEnded) {
        document.querySelector('.Page__Game').classList.add('show');
        isAnimationEnded = true;
      }
    });
   
  }


  onLoad() {
    this.toLoad--;
    if (this.toLoad === 0) {
      this.onLoadComplete();
    }
  }

  onLoadComplete() {
    firebase.analytics().logEvent('loaded');
    setTimeout(() => {
      const loadingEl = document.querySelector('.Page__Loading');
      loadingEl.classList.add('hide');
      document.querySelectorAll('.Snake_Animation__Container').forEach((el) => {
        el.classList.add('animation');
      });
      
      document.querySelectorAll('.Snake_Animation__Bottle').forEach((el) => {
        el.classList.add('animation');
      });

      setTimeout(() => {
        loadingEl.style.display = 'none';
      }, 250);

      this.logoAnim.play();
    }, 1000);
  }
}