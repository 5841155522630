import * as PIXI from "pixi.js";






// import vhCheck from 'vh-check'
// const test = vhCheck()

window.addEventListener("DOMContentLoaded", (event) => {
  

  const isTouch = is_touch_device4();

  if (isTouch) {
    firebase.analytics().logEvent('touch_device');
    document.body.classList.add('touch');
    
  }
  
  resizeContainer();
  checkLandscape();
});

const landscapeEl = document.querySelector('.Page__Landscape')

window.addEventListener('resize', function() {
  resizeContainer();
  checkLandscape();
})



function resizeContainer() {

  if (!is_touch_device4()){
    return;
  }

  const innerHeight =  window.innerHeight + 'px';
  document.querySelectorAll('.container').forEach((el) => {
    el.style.height = innerHeight;
  });
}


import bottle from "./assets/images/snake/gel.png";
import snakeHeadAsset from "./assets/images/snake/head.png";
import snakeBodyAsset from "./assets/images/snake/body.png";
import snakeTailAsset from "./assets/images/snake/tail.png";
import snakeAngleAsset from "./assets/images/snake/angle.png";

import Snake from "./Snake/Snake";
import Food from "./Food/Food";
import Controls from "./Game/Controls";
import Text from "./Game/Text";
import Collision from "./Collision/Collision";
import { Status, RUNNING } from "./Game/Status";
import App from "./App";

import Intro from './Intro'


window.PIXI = PIXI;


const windowWidth = 364;
const windowHeight = 527;

const ONE_BLOCK_IN_PIXELS = 20;
const SCREEN_OUT_OF_BOUNDS_OFFSET = 10;

const MAXIMUM_X_GRID_POSITIONS = windowWidth / (ONE_BLOCK_IN_PIXELS + SCREEN_OUT_OF_BOUNDS_OFFSET);
const MAXIMUM_Y_GRID_POSITIONS = windowHeight / (ONE_BLOCK_IN_PIXELS + SCREEN_OUT_OF_BOUNDS_OFFSET);


const snakeApplication = new App(windowWidth, windowHeight);

snakeApplication.loader
  .add("bottle", bottle)
  .add("snakeHead", snakeHeadAsset)
  .add("snakeBody", snakeBodyAsset)
  .add("snakeTail", snakeTailAsset)
  .add("snakeAngle", snakeAngleAsset)
  .load(setup);

function setup() {
  const ressources = snakeApplication.loader.resources;

  const collision = new Collision(windowWidth, windowHeight);
  const snake = new Snake(new window.PIXI.Graphics(), collision, {
    head: ressources.snakeHead.texture,
    body: ressources.snakeBody.texture,
    tail: ressources.snakeTail.texture,
    angle: ressources.snakeAngle.texture
  });

  const food = new Food(5, 2, ressources.bottle.texture);

  const status = new Status(
    new window.PIXI.Graphics(),
    new Text("", windowHeight - 30, 20)
  );

  snakeApplication.setSnakeInstance(snake);
  snakeApplication.setStatusInstance(status);
  

  new Controls(snake, status).create();

  let run = false;
  let isFirstRender = true;
  


  new Intro(status, function() {
    snakeApplication.ticker.add(() => {
      if (!run) {
        update();
        
      }
      run = !run;
    });
  
    
    snakeApplication.directDraw(() => {
      console.log('directDraw');
      [snake, food, status].forEach(f => f.draw());
    });
  
  
    function update() {
      if (status.status !== RUNNING) return;
  
      if (snake.hasCollidedWithSelf() || snake.hasGoneOutOfBounds()) {
        // DIED_SOUND.play();
        snake.flash();
        status.setLost();
        snakeApplication.endGame();
        return;
      }
  
      snake.move();
      [snake, food, status].forEach(f => f.draw());
  
      if (collision.hasCollided(snake.getHead(), food)) {
        // EAT_SOUND.play();
        snakeApplication.levelUp();
        snake.grow(snakeApplication.stage);
        food.randomlyReposition(
          snake,
          MAXIMUM_X_GRID_POSITIONS,
          MAXIMUM_Y_GRID_POSITIONS
        );
      }
    }
  
  
    snakeApplication.stage.addChild.apply(
      snakeApplication.stage,
      snake.toStage()
    );
    snakeApplication.stage.addChild(food.graphics);
  });
}

document.getElementById("snake__game").appendChild(snakeApplication.view);

function checkLandscape() {
  if (!is_touch_device4()){
    return;
  }

  const width = window.innerWidth;
  const height = window.innerHeight;
  if (width > height) {
    landscapeEl.classList.remove('hide')
    snakeApplication.pause();
  } else {
    landscapeEl.classList.add('hide')
    snakeApplication.resume();
  }
}


function is_touch_device4() {
    
  var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  
  var mq = function (query) {
      return window.matchMedia(query).matches;
  }

  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
      return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}