

export default class InGameScore {
  
  constructor(opts) {

    this.el_header_game = document.querySelector('.header_game');

    this.preloadNumbers();

  }

  loadImage() {
    this.update({
      score: 0,
      bottle: 0
    });
  }

  preloadNumbers() {
    const numbers = '0123456789';
    const toLoad = [];

    const a_numbers = Array.from(numbers);
    let nb_to_load = a_numbers.length;
    a_numbers.forEach((num) => {
      const t = new Image();
      t.src = './images/numbers/number_' + num + '.png';
      t.onload = () => {
        nb_to_load--

        if (nb_to_load === 0) {
          this.loadImage();
        }
      };
    })

    
    
    this.reset();
    
  }

  reset() {
    this.score_img = [];
    this.bottle_score_img = [];

    this.el_header_game.querySelector('.score').innerHTML = '';
    this.el_header_game.querySelector('.bottle_counter').innerHTML = '';

    this.initNumberImgTags(this.score_img, this.el_header_game.querySelector('.score'))  
    this.initNumberImgTags(this.bottle_score_img, this.el_header_game.querySelector('.bottle_counter'))
  }

  initNumberImgTags(toPush, el) {
    for (let i = 0; i < 20; i++) {
      var img = document.createElement("img");
      // img.src = `./images/numbers/number_${num}.png`;
      toPush.push(img);
      el.appendChild(img)
    }
  }



  update(opts) {

    this.renderNumberFromImage(opts.score, this.score_img);
    this.renderNumberFromImage(opts.bottle, this.bottle_score_img);
  }

  renderNumberFromImage(number, array) {

    const string_score = number.toString();
    const a_score = Array.from(string_score)
  
    a_score.forEach((num, idx) => {
      array[idx].src = `./images/numbers/number_${num}.png`;
    });
  }
}