const RUNNING = 'running';
const PAUSED = 'paused';
const LOST = 'lost';
const STOP = 'stop';

class Status {
  constructor(graphics, text, status = RUNNING) {
    this._graphics = graphics;
    this._text = text;
    this._status = status;

    this.mobileControlesEl = document.querySelector('.Game__Canvas__Control');
    this.elPauseBox = document.querySelector('.pause');
    this.elPauseBtn = document.querySelector('.pause-btn');

    this.elPauseBox.addEventListener('touchend', () => {
      this.setRunning();
    })
    
    this.elPauseBtn.addEventListener('click', () => {
      if ( this._status === PAUSED) {
        this.setRunning();
      } else {
        this.setPaused();
      }
      
    })
  }

  get graphics() {
    return this._text.text;
  }

  get status() {
    return this._status;
  }

  setRunning() {
    this._status = RUNNING;
    this.elPauseBox.classList.add('hide');
    this.mobileControlesEl.classList.remove('hide');
  }

  setPaused() {
    this._status = PAUSED;
    this.elPauseBox.classList.remove('hide');
    this.mobileControlesEl.classList.add('hide');
  }

  setStop() {
    this._status = STOP;
  }

  setLost() {
    this._status = LOST;
    this.mobileControlesEl.classList.add('hide');
  }

  draw() {
    this._text.text.text = this._status;
  }
}

export { Status, RUNNING };
