
import HighScore from './Game/HighScore';
import InGameScore from './Game/InGameScore';


const BOTTLE_SCORE = 50;



export default class App {
  constructor(windowWidth, windowHeight) {
    this.app = new window.PIXI.Application({
      width: windowWidth,
      height: windowHeight,
      antialias: true,
      transparent: false,
      resolution:2,
      autoResize:true,
      backgroundColor: 0xFF4155
    });

    this.highscore = new HighScore(this);
    this.ingameScore = new InGameScore();

    this.statusInstance = null;
    this.snakeInstance = null;

    this.newGame();

    

    
  }

  
  get loader() {
    return this.app.loader;
  }

  get ticker() {
    return this.app.ticker;
  }

  get stage() {
    return this.app.stage;
  }

  get view() {
    return this.app.view;
  }

  setStatusInstance(status) {
    this.statusInstance = status;
  }

  setSnakeInstance(snake) {
    this.snakeInstance = snake;
  }

  pause() {
    if (this.statusInstance !== null) {
      this.statusInstance.setPaused();
    }
  }
  resume() {
    /* if (this.statusInstance !== null) {
      this.statusInstance.setRunning();
    } */
  }

  newGame() {
    firebase.analytics().logEvent('new_game');
    this.score = 0;
    this.bottle = 0;

    this.app.ticker.maxFPS = 10;

    

    this.ingameScore.reset();
    this.updateScore();

    if (this.statusInstance !== null) {
      this.statusInstance.setRunning();
    }

    if (this.snakeInstance !== null) {
      this.stage.removeChild.apply(
        this.stage,
        this.snakeInstance.toStage()
      );

      this.snakeInstance.reset();

      this.stage.addChild.apply(
        this.stage,
        this.snakeInstance.toStage()
      );

      this.directDraw();
    }
  }

  directDraw(cb = () =>{}) {
    cb();
  }

  endGame() {
    this.highscore.getLatest((score, all) => {

      if (this.score > 0 && (all.length < 15 || this.score > score )) {
        firebase.analytics().logEvent('high_score');
        this.highscore.displayForm(this.score, this.bottle);
      } else {
        // document.querySelector('.game-over').classList.remove('hide');
        this.highscore.displayScoresTable();
        firebase.analytics().logEvent('game_over');
      }
    });
  }

  updateScore() {
    this.ingameScore.update({
      score: this.score,
      bottle: this.bottle
    })
  }

  levelUp() {
    this.score += 50;
    this.bottle++;

    if (this.score >= 2000) {
      this.app.ticker.maxFPS = 14;
    }

    if (this.score >= 4000) {
      this.app.ticker.maxFPS = 16;
    }

    if (this.score >= 5000) {
      this.app.ticker.maxFPS = 18;
    }

    if (this.score >= 6000) {
      this.app.ticker.maxFPS = 20;
    }

    if (this.score >= 7000) {
      this.app.ticker.maxFPS = 22;
    }

    if (this.score >= 8000) {
      this.app.ticker.maxFPS = 24;
    }

    console.log('FPS', this.app.ticker.FPS);

    this.updateScore();
  }
}
